import * as $ from 'jquery'
window.Popper = require('@popperjs/core');
import { bootstrap, Tooltip, Toast, Popover } from 'bootstrap'
//import 'owl.carousel'
import { Fancybox } from "@fancyapps/ui"
import { each } from 'jquery'
import validate from 'jquery-validation'
import Swiper from 'swiper/bundle'
// import { Navigation, Pagination } from 'swiper/modules'
import "script-loader!../node_modules/nouislider/dist/nouislider.min.js"
import "script-loader!../node_modules/wnumb/wNumb.min.js"
import SimpleBar from 'simplebar';
import ResizeObserver from 'resize-observer-polyfill';

(function ($) {

	var $nWindowWidth = $(window).width(),
		$nWindowHeight = $(window).height(),
		$nClientWidth = Math.ceil($("body").prop("clientWidth")),
		$nClientHeight = Math.ceil($("body").prop("clientHeight")),
		$bProductReviewsExist = false,
		$objTooltipActive = false,
		$nHeaderContainerHeight = 0,
		$objBooksCatalogNav = false

	$(document).ready(function () {

		$('[data-bs-toggle="tooltip"]').tooltip()

		Fancybox.bind("[data-fancybox]", {});

		$('.header__hamburgers > .hamburger').on('click', function (event) {
			event.preventDefault()

			if ($('body').hasClass('--open-catalog-book-modal'))
				$('body').removeClass('--open-catalog-book-modal')
			else {
				$(this).toggleClass('is-active')
				$('body').toggleClass('body--nav-show')
			}
		})

		$("#open-catalog-book-modal").on('click', function () {
			if ($objBooksCatalogNav) $objBooksCatalogNav.recalculate()
			$("body").addClass('--open-catalog-book-modal')
		})

		$(".header__books-modal .books-modal__close").on('click', function () {
			$("body").removeClass('--open-catalog-book-modal')
		})

		$('.popup-menu__close').on("click", function (e) {
			$('body').removeClass('--overlay --popup-menu-show')
		})

		$('[data-modal]').on('click', function (event) {
			event.preventDefault()
			$('.popup__modal.--active, .popup__modal-thanks.--active, .popup__modal-subscribe.--active').removeClass('--active')
			var modalName = "popup-" + $(this).attr('data-modal')
			$('body').addClass('--popup-show')
			$('#' + modalName).addClass('--active')
		})

		$('.contacts__connect-info .connect-info__text span.text').on('click', function (e) {
			$(this).siblings('[data-modal-tooltip]').trigger('click')
		})

		$('[data-modal-tooltip]').on('click', function (event) {

			event.preventDefault()

			$('.popup__tooltip.--active').removeClass('--active')

			var modalTooltipName = "popup-tooltip-" + $(this).attr('data-modal-tooltip')

			/*
				bottom: 452.078125
				height: 30
				left: 1062.796875
				right: 1251.78125
				top: 422.078125
				width: 188.984375
				x: 1062.796875
				y: 422.078125
			*/

			$objTooltipActive = $('#' + modalTooltipName)

			updatePopupTooltipPosition()

			$('body').addClass('--popup-show')
			$('#' + modalTooltipName).addClass('--active')
		})

		$('.modal-header__close, .popup__tooltip-close').on('click', function (event) {
			event.preventDefault()
			$(this).closest('.popup__modal').removeClass('--active')
			$(this).closest('.popup__tooltip').removeClass('--active')
			$('body').removeClass('--popup-show')
			$objTooltipActive = false
		})

		if ($('#books-catalog-nav').length) {
			var objBooksCatalogNav = new SimpleBar(document.getElementById('books-catalog-nav'))
		}

		$(document).on('click', 'span.icon.--eye-hide', function (event) {
			$(this).siblings('input[type="password"]').attr('type', 'text')
			$(this).removeClass('--eye-hide').addClass('--eye-show')
		})

		$(document).on('click', 'span.icon.--eye-show', function (event) {
			$(this).siblings('input[type="text"]').attr('type', 'password')
			$(this).removeClass('--eye-show').addClass('--eye-hide')
		})

		if ($(".catalog-product__reviews-list .reviews-list__item").length)
			$bProductReviewsExist = true

		$(document).on('click', '.catalog-product__reviews-list .reviews-list__item-text .item-text__show-all', function (event) {
			event.preventDefault();

			var showText = $(this).data('show-text'),
				hideText = $(this).data('hide-text');

			if ($(this).hasClass('--open')) {

				$(this).siblings('.item-text__wrap').removeClass("--full-text");
				$(this).removeClass('--open').html(showText);


			}
			else {

				$(this).siblings('.item-text__wrap').addClass("--full-text");
				$(this).addClass('--open').html(hideText);

			}

		});

		$('.popup__modal-body .modal-body__rating-star').on({

			mouseenter: function () {

				var currentIndex = $(this).index()

				$(this).parent().addClass('--hover')

				$('.popup__modal-body .modal-body__rating-star:lt(' + (currentIndex + 1) + ')').addClass('--active')

			},
			mouseleave: function () {

				$(this).parent().removeClass('--hover')

				$('.popup__modal-body .modal-body__rating-star').removeClass('--active')

			}

		}).on('click', function () {

			var currentIndex = $(this).index()
			$('#rating-star').val(currentIndex + 1)
			$('.popup__modal-body .modal-body__rating-star').removeClass('--active --selected')
			$('.popup__modal-body .modal-body__rating-star:lt(' + (currentIndex + 1) + ')').addClass('--active --selected')

		})

		if ($bProductReviewsExist)
			updateProductReviewsShowAll()

		$(".catalog__filter-block .filter-block__title").on("click", function (e) {
			$(this).closest('.catalog__filter-block').toggleClass('--expanded')
		})

		/*if ($('#catalog-filter-price-slider').length) {

			var objCatalogFilterPriceSlider = document.getElementById('catalog-filter-price-slider'),
				objPriceFormatSum = wNumb({ thousand: ' ', decimals: 0 })

			noUiSlider.create(objCatalogFilterPriceSlider, {
				start: [0, 10678],
				connect: true,
				range: {
					'min': 0,
					'max': 10678
				}
			})

			objCatalogFilterPriceSlider.noUiSlider.on('slide', function (floatValues, change, intValues) {

				var sumMin = parseInt(intValues[0]),
					sumMax = parseInt(intValues[1])

				$('#filter-price-from-input').val(sumMin)
				$('#filter-price-to-input').val(sumMax)
				$('#filter-price-from').html(objPriceFormatSum.to(sumMin))
				$('#filter-price-to').html(objPriceFormatSum.to(sumMax))

			})

		}*/

		$(".frontend .sortable__select").on("click", function (e) {

			if ($(".sortable__select-value").is(e.target) || $(".sortable__select-value span").is(e.target))
				$(this).toggleClass('--open')

		})

		$(".frontend .sortable__select-list .select-list__item a").on("click", function (e) {

			if (!$(this).parent().hasClass('--active')) {
				$(this).parent().removeClass('--active').siblings().removeClass('--active')
				$(this).parent().addClass('--active')
				var value = $(this).html()
				$(this).closest('.sortable__select-list').siblings('.sortable__select-value').children('span').html(value)

			}

			$(this).closest('.sortable__select').removeClass('--open')

		})

		if ($(".product-picture__photo-list.swiper").length) {

			var objSwiperProductPhotosListConfig = {
				slidesPerView: 1,
				spaceBetween: 40,
				loop: false
			}

			var arProductPhotosSwiper = []

			$(".product-picture__photo-list.swiper").each(function (i, el) {

				arProductPhotosSwiper[i] = new Swiper(el, objSwiperProductPhotosListConfig)

				$(el).closest('.catalog-product__picture').find('.product-picture__slides-item').on('click', function () {
					arProductPhotosSwiper[i].slideTo($(this).index())
				})

			})
		}

		var objSwiperProductsListConfig = {
			slidesPerView: 'auto',
			spaceBetween: 20,
			centeredSlides: false,
			loop: false,
			navigation: {
				nextEl: '#authors-book-next',
				prevEl: '#authors-book-prev'
			},
			breakpoints: {
				0: {
					slidesPerView: 'auto',
					spaceBetween: 20,
					centeredSlides: true,
					loop: true
				},
				576: {
					slidesPerView: 'auto',
					spaceBetween: 7,
					centeredSlides: false,
					loop: true
				},
				768: {
					slidesPerView: 2,
					spaceBetween: 186,
					centeredSlides: false,
					loop: false
				},
				992: {
					slidesPerView: 3,
					spaceBetween: 88,
					centeredSlides: false,
					loop: false
				},
				1170: {
					slidesPerView: 4,
					spaceBetween: 34,
					centeredSlides: false,
					loop: false
				},
				1440: {
					slidesPerView: 5,
					spaceBetween: 22,
					centeredSlides: false,
					loop: false
				},
				1500: {
					slidesPerView: 5,
					spaceBetween: 37,
					centeredSlides: false,
					loop: false
				}

			}
		},
			objSwiperAuthorsListConfig = {
				slidesPerView: 'auto',
				spaceBetween: 20,
				centeredSlides: false,
				loop: false,
				navigation: {
					nextEl: '#authors-popular-next',
					prevEl: '#authors-popular-prev',
				},
				breakpoints: {
					0: {
						slidesPerView: 'auto',
						spaceBetween: 20,
						centeredSlides: true,
						loop: true
					},
					576: {
						slidesPerView: 'auto',
						spaceBetween: 7,
						centeredSlides: false,
						loop: true
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 186,
						centeredSlides: false,
						loop: false
					},
					992: {
						slidesPerView: 3,
						spaceBetween: 88,
						centeredSlides: false,
						loop: false
					},
					1170: {
						slidesPerView: 4,
						spaceBetween: 34,
						centeredSlides: false,
						loop: false
					},
					1440: {
						slidesPerView: 5,
						spaceBetween: 22,
						centeredSlides: false,
						loop: false
					},
					1500: {
						slidesPerView: 5,
						spaceBetween: 37,
						centeredSlides: false,
						loop: false
					}

				}
			},
			objSwiperPopularProductsListConfig = {
				slidesPerView: 'auto',
				spaceBetween: 20,
				centeredSlides: false,
				loop: false,
				navigation: {
					nextEl: '#popular-books-next',
					prevEl: '#popular-books-prev'
				},
				breakpoints: {
					0: {
						slidesPerView: 'auto',
						spaceBetween: 20,
						centeredSlides: true,
						loop: true
					},
					576: {
						slidesPerView: 'auto',
						spaceBetween: 7,
						centeredSlides: false,
						loop: true
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 186,
						centeredSlides: false,
						loop: false
					},
					992: {
						slidesPerView: 3,
						spaceBetween: 88,
						centeredSlides: false,
						loop: false
					},
					1170: {
						slidesPerView: 4,
						spaceBetween: 34,
						centeredSlides: false,
						loop: false
					},
					1440: {
						slidesPerView: 5,
						spaceBetween: 22,
						centeredSlides: false,
						loop: false
					},
					1500: {
						slidesPerView: 5,
						spaceBetween: 37,
						centeredSlides: false,
						loop: false
					}

				}
			},
			objSwiperNewProductsListConfig = {
				slidesPerView: 'auto',
				spaceBetween: 20,
				centeredSlides: false,
				loop: false,
				navigation: {
					nextEl: '#new-books-next',
					prevEl: '#new-books-prev'
				},
				breakpoints: {
					0: {
						slidesPerView: 'auto',
						spaceBetween: 20,
						centeredSlides: true,
						loop: true
					},
					576: {
						slidesPerView: 'auto',
						spaceBetween: 7,
						centeredSlides: false,
						loop: true
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 186,
						centeredSlides: false,
						loop: false
					},
					992: {
						slidesPerView: 3,
						spaceBetween: 88,
						centeredSlides: false,
						loop: false
					},
					1170: {
						slidesPerView: 4,
						spaceBetween: 34,
						centeredSlides: false,
						loop: false
					},
					1440: {
						slidesPerView: 5,
						spaceBetween: 22,
						centeredSlides: false,
						loop: false
					},
					1500: {
						slidesPerView: 5,
						spaceBetween: 37,
						centeredSlides: false,
						loop: false
					}

				}
			}

		if ($('.products-swiper.--new').length) {
			var objNewProductSwiper = new Swiper($('.products-swiper.--new').get(0), objSwiperNewProductsListConfig)
			$('#new-books-next-mobile').on('click', function () {
				objNewProductSwiper.slideNext()
			})
			$('#new-books-prev-mobile').on('click', function () {
				objNewProductSwiper.slidePrev()
			})
		}

		if ($('.products-swiper.--popular').length) {
			var objPopularProductSwiper = new Swiper($('.products-swiper.--popular').get(0), objSwiperPopularProductsListConfig)
			$('#popular-books-next-mobile').on('click', function () {
				objPopularProductSwiper.slideNext()
			})
			$('#popular-books-prev-mobile').on('click', function () {
				objPopularProductSwiper.slidePrev()
			})
		}


		if ($('.products-swiper.--default').length) {
			var objProductSwiper = new Swiper($('.products-swiper.--default').get(0), objSwiperProductsListConfig)
			$('#authors-book-next-mobile').on('click', function () {
				objProductSwiper.slideNext()
			})
			$('#authors-book-prev-mobile').on('click', function () {
				objProductSwiper.slidePrev()
			})
		}

		if ($('.authors-swiper').length) {
			var objAuthorsSwiper = new Swiper('.authors-swiper', objSwiperAuthorsListConfig)
			$('#authors-popular-next-mobile').on('click', function () {
				objAuthorsSwiper.slideNext()
			})
			$('#authors-popular-prev-mobile').on('click', function () {
				objAuthorsSwiper.slidePrev()
			})
		}

		$(".catalog__products-filters .products-filters__filter").on("click", function () {
			$("body").addClass('--open-catalog-filter')
		})

		$(".catalog__filter-title .filter-title__close").on("click", function () {
			$("body").removeClass('--open-catalog-filter')
		})

		$(".home__books-categories__select-genre").on('click', function () {
			$(this).toggleClass('--visible')
		})

		$('.header__search-form input[type=text]').on('focus', function () {
			$('.header__search-results').addClass('--show')
		}).on('blur', function () {
			$('.header__search-results').removeClass('--show')
		})

		$('.authors-search__form input').on('keyup', function () {

			$('.letter-items__item').removeClass('--hidden')
			$('.authors-list__letter').removeClass('--not-found')
			var val = $(this).val()

			if (val.length > 0) {

				$('.letter-items__item').each(function (index, el) {

					if ($(el).html().toLowerCase().indexOf(val.toLowerCase()) == -1)
						$(el).addClass('--hidden')

				})

				$('.authors-list__letter').each(function (index, el) {

					var nCountHiddenElements = $(el).find('.letter-items__item.--hidden').length,
						nAllHiddenElements = $(el).find('.letter-items__item').length

					if (nCountHiddenElements == nAllHiddenElements)
						$(el).addClass('--not-found')

				})



			}

		})

	})

	$(window).on('load', function () {

		$nWindowWidth = $(window).width()
		$nWindowHeight = $(window).height()
		$nClientWidth = Math.ceil($("body").prop("clientWidth"))
		$nClientHeight = Math.ceil($("body").prop("clientHeight"))

		/*if ($(".randombook__content.--loading").length) {

			setTimeout(function () {
				$(".randombook__content.--loading").removeClass('--loading')
				$('h1').html('Ваша случайная книга...')
			}, 5000)

		}*/

		updateProductReviewsShowAll()
		if ($objBooksCatalogNav) $objBooksCatalogNav.recalculate()

	})

	$(window).on('resize', function (event) {

		$nWindowWidth = $(window).width()
		$nWindowHeight = $(window).height()
		$nClientWidth = Math.ceil($("body").prop("clientWidth"))
		$nClientHeight = Math.ceil($("body").prop("clientHeight"))

		updateProductReviewsShowAll()
		updatePopupTooltipPosition()
		updateMenuBookCatalog()
		if ($objBooksCatalogNav) $objBooksCatalogNav.recalculate()

	})

	$(window).on('scroll', function (event) {

		updatePopupTooltipPosition()

	})
	function updateMenuBookCatalog() {

		if ($("body").hasClass('--open-catalog-book-modal')) {

			if ($nClientWidth > 575) {
				$('.header__hamburgers > .hamburger').removeClass('is-active')
				$("body").removeClass('body--nav-show')
			}
			else {
				$('.header__hamburgers > .hamburger').addClass('is-active')
				$("body").addClass('body--nav-show')
			}

		}

	}

	function updatePopupTooltipPosition() {

		if ($objTooltipActive) {

			var elementActivate = $objTooltipActive.attr('id').replace('popup-tooltip-', ''),
				elementActivatePosition = $('[data-modal-tooltip="' + elementActivate + '"]').get(0).getBoundingClientRect()

			console.log($nClientWidth)
			console.log($objTooltipActive.outerWidth())

			if (($objTooltipActive.outerWidth() + elementActivatePosition.left + elementActivatePosition.width + 31) > $nClientWidth) {
				$objTooltipActive.css({ top: elementActivatePosition.top - 30 + "px", left: elementActivatePosition.left - $objTooltipActive.outerWidth() - 31 + "px" }).addClass('--arrow-right')
			}
			else
				$objTooltipActive.css({ top: elementActivatePosition.top - 30 + "px", left: elementActivatePosition.left + elementActivatePosition.width + 31 + "px" }).removeClass('--arrow-right')

		}

	}

	function updateProductReviewsShowAll() {

		$(".catalog-product__reviews-list .reviews-list__item").each(function (index, element) {

			if (parseInt($(element).find('.item-text__review').height()) > parseInt($(element).find('.item-text__wrap').height()))
				$(element).find('.item-text__show-all').addClass('--active')
			else
				$(element).find('.item-text__show-all').removeClass('--active')

		})

		/*if($('.section-tags').length > 0) {

			if(parseInt($('.section-tags__list').height()) > parseInt($('.section-tags__wrap').height()))
				$('.section-tags__more').removeClass('hidden')
			else
				$('.section-tags__more').addClass('hidden')

		}*/

	}

})(jQuery)